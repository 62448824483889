import React from 'react';

const AvisoLegalPage = () => {
  return (
    <div className="w-full m-0 p-0 container mx-auto pt-9">
      <div className="flex flex-col items-center gap-8">
        <div className="w-full text-center">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-extrabold !text-[var(--text-BannerColor)] leading-[1.55] tracking-[.01px] pb-6">
          DISCLAIMER 
          </h1>
        </div>
        
        <div className="w-full lg:w-5/6 px-4 md:px-6" style={{textAlign: 'justify'}}>
          <div className="space-y-6 lg:space-y-8 text-lg md:text-xl text-gray-600">
            <section style={{textAlign: 'justify'}}>
                <p className="mb-4">
                    Le informamos que Claro Pagos, S.A. de C.V. (en lo sucesivo “T1Pagos”), con domicilio para oír y recibir cualquier tipo de notificaciones ubicado en Lago Zúrich No. 245, Edificio Presa Falcón, piso 7, colonia Ampliación Granada, delegación Miguel Hidalgo, C.P. 11529, Ciudad de México, México. T1Pagos para la prestación de sus servicios, recabará información relativa a personas morales y/o de personas físicas en su calidad de comerciantes.  
                </p>
                <p>
                    En este sentido, le informamos que toda la información que nos llegue a proporcionar las personas físicas en su calidad de comerciantes y/o las  personas morales no se encuentra comprendida dentro del ámbito de aplicación de la Ley Federal de Protección de Datos Personales en Posesión de Particulares y demás normatividad aplicable.
                </p>
                <p className="mb-4">
                    No obstante, lo anterior, para T1Pagos la confidencialidad y debida protección de su información es de máxima importancia, razón por la cual está comprometida a manejar su información de manera responsable y conforme a las disposiciones legales aplicables.
                </p>
                <p>
                    Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos de T1Pagos a la dirección electrónica 
                    {" "}
                <a href="mailto:arco@t1pagos.com" className="text-[var(--colorT1)] font-bold hover:underline">
                    arco@t1pagos.com
                </a>
                </p>
            </section>

          </div>
        </div>
      </div>
    </div>
  );
};

export default AvisoLegalPage;